import React from 'react'
import { Input, Segment } from 'semantic-ui-react'
import axios from 'axios'
import qs from 'qs'

export default class ProcurementSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: [
        {"price":20,"picture":"https://www.thesun.co.uk/wp-content/uploads/2017/03/fifa-17-2.jpg?strip=all&w=742&quality=100","title":"RoseShop","quantity":10}
      ],
      id: 0
    }
  }

  componentDidMount() {
    axios.get('https://rose-chat-bot.jkrn.me/apis/products')
      .then(res => {
        const products = res.data;
        this.setState({ products })
        this.setState({id: products[0].id})
      })
  }

  handleChange = event => {
    console.log(event.target)
    this.setState({ [event.target.name]: event.target.value })
    console.log(this.state)
  }

  handleSubmit = event => {
    event.preventDefault();

    console.log(this.state)

    let thisProduct = {}
    axios.get('https://rose-chat-bot.jkrn.me/apis/products/' + this.state.id)
    .then(res => {
      thisProduct = res.data;
    })

    const product = qs.stringify({
      title: thisProduct.title,
      price: thisProduct.price,
      picture: thisProduct.picture,
      quantity: this.state.quantity
    })

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }

    axios.put('https://rose-chat-bot.jkrn.me/apis/product/' + this.state.id, product, headers)
    .then(res => {
      console.log(res)
      window.location.reload();
    })
  }

  render() {
    return (
      <div>
        <Segment attached>
          <h2>Procurement Management</h2>
          <form onSubmit={this.handleSubmit}>
            <div style={{marginHorizontal: 20}}>
              <select name="id" value={this.state.id} className="ui dropdown fluid" onChange={this.handleChange}>
                {
                    this.state.products.map((data, key) => (
                      <option value={data.id} key={key}>{data.title}</option>
                    ))
                }
              </select>
              <br/>
              <Input type='text' label='Quantity' name='quantity' onChange={this.handleChange} fluid />
            </div>
            <br/>
            <button className="ui pink fluid button" type="submit">Set new quantity to product</button>
          </form>
        </Segment>
      </div>
    )
  }
}