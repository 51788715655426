import React from 'react';
import ProductTable from './components/ProductTable'
import { Container } from 'semantic-ui-react';
import AddProductSection from './components/AddProductSection';
import ProcurementSection from './components/ProcurementSection';

export default class App extends React.Component {
  render() {
    return (
      <Container style={{ marginTop: '5em' }}>
        <center><h1>Rose ChatBot Admin Panel</h1></center>
        <ProductTable />
        <div className="ui grid">
          <div className="eight wide column">
            <AddProductSection />
          </div>
          <div className="eight wide column">
            <ProcurementSection />
          </div>
        </div>
      </Container>
    )
  }
}
