import React from 'react'
import { Header, Table, Segment, Image } from 'semantic-ui-react'
import axios from 'axios';

export default class ProductTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      products: []
    }
  }

  componentDidMount() {
    axios.get('https://rose-chat-bot.jkrn.me/apis/products')
      .then(res => {
        const products = res.data;
        this.setState({ products });
      })
  }

  deleteItem = (i) => {
    axios.delete('https://rose-chat-bot.jkrn.me/apis/product/' + i)
      .then(res => {
        console.log(res.data)
        window.location.reload();
      })
  }

  render() {
    return (
      <Segment>
      <h2>Product List</h2>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Seq</Table.HeaderCell>
            <Table.HeaderCell>Product Id</Table.HeaderCell>
            <Table.HeaderCell>Product Name</Table.HeaderCell>
            <Table.HeaderCell>Price (Dollar)</Table.HeaderCell>
            <Table.HeaderCell>Picture</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            this.state.products.map((data, key) => (
              <Table.Row key={key}>
                <Table.Cell>
                  <Header textAlign='center'>{key+1}</Header>
                </Table.Cell>
                <Table.Cell singleLine>{data.id}</Table.Cell>
                <Table.Cell singleLine>{data.title}</Table.Cell>
                <Table.Cell textAlign='right' singleLine>{"$" + data.price}</Table.Cell>
                <Table.Cell><Image src={data.picture} size='small' /></Table.Cell>
                <Table.Cell textAlign='right'>{data.quantity}</Table.Cell>
                <Table.Cell>
                  <button className="ui negative basic button" onClick={() => {this.deleteItem(data.id)}}>Delete</button>
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </Segment>
    )
  }
}