import React from 'react'
import { Input, Segment } from 'semantic-ui-react'
import axios from 'axios'
import qs from 'qs'

export default class AddProductSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      price: 0,
      picture: "",
      quantity: 0
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault();

    const product = qs.stringify({
      title: this.state.title,
      price: this.state.price,
      picture: this.state.picture,
      quantity: this.state.quantity
    })

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }

    axios.post('https://rose-chat-bot.jkrn.me/apis/product', product, headers)
    .then(res => {
      console.log(res)
      window.location.reload();
    })
  }

  render() {
    return (
      <div>
        <form className="ui form" onSubmit={this.handleSubmit}>
          <Segment attached>
          <h2>Create New Product</h2>
            <div style={{marginHorizontal: 20}}>
              <Input type='text' label='Product Name' name="title" onChange={this.handleChange} fluid />
              <br/>
              <Input type='text' label='Price' name="price" onChange={this.handleChange} fluid />
              <br/>
              <Input type='text' label='Picture' name="picture" onChange={this.handleChange} fluid />
              <br/>
              <Input type='text' label='Initial Quantity' name="quantity" onChange={this.handleChange} fluid />
            </div>
            <br/>
            <button className="ui teal fluid button" type="submit">Create new product</button>
          </Segment>

        </form>
      </div>
    )
  }
}